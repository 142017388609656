





import Error from '../../../../components/contents/Error.vue';
import { Component, Emit, Vue } from 'vue-property-decorator';
import {Route} from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '@/store';
import { CourseResponseCourses } from 'types/typescript-axios/api';
import { isCourseApplicationMethod, courseTimeStatus, isReservedStatus, isReservedStatus2 } from '../../../../plugins/helper';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function getDatas(to: Route) {
  let status: number = 200;
  let url: string = '/wp-json/moc/course/';

  const $to: any = to;

  const paramsName: number = $to.params.courceId;
  url = `${url}${paramsName}/detail`;

  const contentsData = await axios.get<AxiosResponse>(url);
  status = contentsData.status;

  return {
    status,
    contentsData,
    paramsName,
    $to,
  };
}

@Component({
  components: {
    Error,
  },
})
export default class Detail extends Vue {
  private title: string = '';
  private courseDetail: CourseResponseCourses | null = null;
  private status = 200;
  private paramsName: number = 0;

  private stepNavi: StepNaviData[] = [
    {
      title: '申込みキャンセル',
    },
    {
      title: 'キャンセル完了',
    },
  ];

  @Emit()
  private setCourseDetail(value: any): void {
    this.courseDetail = value;
  }

  @Emit()
  private setTitle(value: any): void {
    this.title = value;
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    const courseDetail: CourseResponseCourses = data.contentsData.data.courses[0];
    const status1: number = isReservedStatus(courseDetail);
    const status2: number = isReservedStatus2(courseDetail);
    const timeStatus: number = courseTimeStatus(courseDetail);
    if (timeStatus === 3 || status1 === 1 || (status1 === 2 && status2 === 2)) {
      store.commit('window/setTitle', courseDetail.data2.title.rendered);
      next((vm: {status: number}) => {
        vm.status = 404;
      });
    } else if (data.status === 200) {
      store.commit('window/setTitle', courseDetail.data2.title.rendered);
      next((vm: {
        title: string,
        courseDetail: CourseResponseCourses,
        paramsName: number,
        status: number,
      }) => {
        vm.status = data.status;
        vm.courseDetail = courseDetail;
        vm.title = courseDetail.data2.title.rendered!;
        vm.paramsName = Number(data.$to.params.courceId);
      });
    } else {
      store.commit('window/setTitle', data.status + ' Error ');
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    const courseDetail: CourseResponseCourses = data.contentsData.data.courses[0];
    this.status = data.status;
    const status1: number = isReservedStatus(courseDetail);
    const status2: number = isReservedStatus2(courseDetail);
    const timeStatus: number = courseTimeStatus(courseDetail);
    if (timeStatus === 3 || status1 === 1 || status1 === -1 || (status1 === 2 && status2 === 2)) {
      store.commit('window/setTitle', courseDetail.data2.title.rendered);
      next((vm: {status: number}) => {
        vm.status = 404;
      });
    } else if (data.status === 200) {
      store.commit('window/setTitle', courseDetail.data2.title.rendered);
      const $to: any = to;
      this.courseDetail = courseDetail;
      this.title = courseDetail.data2.title.rendered!;
      this.paramsName = Number($to.params.courceId);
    } else {
      store.commit('window/setTitle', data.status + ' Error ');
    }
    next();
  }
}
